import React, { useEffect, useRef, useState } from 'react';
import SelectedProjects from './SelectedProjects';

const GradientBorder = ({ children, colorRGB, viaColorRGB, className, clockwiseAnimation = true, passiveBorder = false, followsMouse = true, sizeMultiplier = 1.0, fadeOutDuration = 10000, fadeInDuration = 10000, animate = false }) => {

    const containerRef = useRef(null);
    const animationRef = useRef(null);
    const fadeOutTimeoutRef = useRef(null); // Reference to store the fade-out timeout
    const fadeInTimeoutRef = useRef(null); // Reference to store the fade-out timeout
    const restartAnimationTimeoutRef = useRef(null); // Reference to store the fade-out timeout
    const shouldAnimateRef = useRef(false);

    const [gradientPosition, setGradientPosition] = useState('50% 50%');
    const [gradientOpacity, setGradientOpacity] = useState(0);
    const [gradientSize, setGradientSize] = useState(0);
    const [divWidth, setDivWidth] = useState(0);

    const fadeOutInterval = 30;
    const fadeInInterval = 30;
    const totalFadeOutDuration = fadeOutDuration;
    const totalFadeInDuration = fadeInDuration;
    const colorRGBString = `${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}`
    const viaColorRGBString = (viaColorRGB == null) ? colorRGBString : `${viaColorRGB[0]}, ${viaColorRGB[1]}, ${viaColorRGB[2]}`

    const updateDivWidth = () => {
        if (containerRef.current) {
            setDivWidth(containerRef.current.offsetWidth);
            setGradientSize(`${containerRef.current.offsetWidth / 2 * sizeMultiplier}px`);
        }
    };

    useEffect(() => {

        const updateSize = () => {
            updateDivWidth();
        };
        updateSize();

        // Set up Resize Observer
        const resizeObserver = new ResizeObserver(() => {
            updateSize();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        const handleMouseMove = (e) => {
            if (!followsMouse) return;
            if (!containerRef.current) return;
            const { left, top, width, height } = containerRef.current.getBoundingClientRect();
            const x = ((e.clientX - left) / width) * 100;
            const y = ((e.clientY - top) / height) * 100;
            setGradientPosition(`${x}% ${y}%`);
        };

        const container = containerRef.current;
        container.addEventListener('mousemove', handleMouseMove);

        if (animate) {
            fadeIn();
            startAnimation();
        }

        return () => {
            if (shouldAnimateRef.current) {
                stopAnimation();
            }
            container.removeEventListener('mousemove', handleMouseMove);
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [animate]);

    const startAnimation = () => {

        let currentTime = 0;
        const animationStep = 0.5; // Degrees to rotate per step
        const animationDuration = 100; // Duration for a complete rotation (in ms)

        shouldAnimateRef.current = true;

        const animate = () => {

            if (!shouldAnimateRef.current) {
                return
            }

            if (currentTime >= animationDuration) {
                currentTime = 0;
            }

            let x = 50;
            let y = 50;

            const angle = (currentTime / animationDuration) * 360;
            const angleRads = (angle * Math.PI) / 180;

            if (clockwiseAnimation) {
                x += Math.cos(angleRads) * 50;
                y += Math.sin(angleRads) * 50;
            } else {
                x += Math.sin(angleRads) * 50;
                y += Math.cos(angleRads) * 50;
            }

            setGradientPosition(`${x}% ${y}%`);
            currentTime += animationStep;

            requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);
    };

    const stopAnimation = () => {
        shouldAnimateRef.current = false;
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
    };

    const clearFadeOut = () => {
        if (fadeOutTimeoutRef.current) {
            clearTimeout(fadeOutTimeoutRef.current);
            fadeOutTimeoutRef.current = null;
        }
    };

    const clearFadeIn = () => {
        if (fadeInTimeoutRef.current) {
            clearTimeout(fadeInTimeoutRef.current);
            fadeInTimeoutRef.current = null;
        }
    };

    const clearRestartAnimation = () => {
        if (restartAnimationTimeoutRef.current) {
            clearTimeout(restartAnimationTimeoutRef.current);
            restartAnimationTimeoutRef.current = null;
        }
    };

    const fadeIn = (step = 0) => {
        const newOpacity = 0 + (step / (totalFadeInDuration / fadeInInterval));
        setGradientOpacity(newOpacity);
        step += fadeInInterval;

        if (step <= totalFadeInDuration) {
            fadeInTimeoutRef.current = setTimeout(() => fadeIn(step), fadeInInterval);
        }
    };

    const fadeOut = (step = 0) => {
        const newOpacity = 1 - (step / (totalFadeOutDuration / fadeOutInterval));
        setGradientOpacity(newOpacity);
        step += fadeOutInterval;

        if (step <= totalFadeOutDuration) {
            fadeOutTimeoutRef.current = setTimeout(() => fadeOut(step), fadeOutInterval);
        }
    };

    const handleMouseEnter = () => {
        clearFadeOut();
        clearFadeIn();
        clearRestartAnimation();

        if (followsMouse) {
            if (animate && shouldAnimateRef.current) {
                stopAnimation();
            } else {
                fadeIn();
            }
        }
    };

    const handleMouseLeave = () => {
        clearFadeOut();
        clearFadeIn();
        clearRestartAnimation();

        if (followsMouse) {
            if (animate) {
                startAnimation();
            } else {
                fadeOut();
            }
        }
    };

    return (
        <div className={`relative ${className}`} ref={containerRef}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             style={{
                 padding: '0px', // Padding for border space
             }}>
            {/* Pseudo-element for Gradient Border */}
            { passiveBorder ? (
                <div className={`absolute inset-0 z-0 ${className}`}
                    style={{
                        opacity: '15%',
                        position: 'absolute',
                        zIndex: -1,
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        maskComposite: 'exclude',
                        WebkitMaskComposite: 'xor',
                        background: `white`,
                        borderRadius: 'inherit',
                    }}
                ></div>
            ): (
                <div/>
            )}
            <div className={`absolute inset-0 z-1 ${className}`}
                 style={{
                     content: '',
                     position: 'absolute',
                     zIndex: -1,
                     mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                     WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                     maskComposite: 'exclude',
                     WebkitMaskComposite: 'xor',
                     background: `radial-gradient(circle ${gradientSize} at ${gradientPosition}, rgba(${colorRGBString}, ${gradientOpacity}) 0%, rgba(${viaColorRGBString}, ${gradientOpacity}) 15%, rgba(21, 21, 21, 0))`,
                     borderRadius: 'inherit',
                 }}
            ></div>
    
            {/* Content */}
            <div className="relative z-10">
                {children}
            </div>
        </div>
    );

    // return (
    //     <div
    //         className={`relative ${className}`}
    //         style={{
    //             background: `radial-gradient(circle ${gradientSize} at ${gradientPosition}, rgba(${colorRGBString}, ${gradientOpacity}) 0%, rgba(${viaColorRGBString}, ${gradientOpacity}) 15%, rgba(21, 21, 21, 0.0))`,
    //             transition: 'opacity 0.3s ease',
    //         }}
    //         ref={containerRef}
    //         onMouseEnter={handleMouseEnter}
    //         onMouseLeave={handleMouseLeave}
    //     >
    //         {children}
    //     </div>
    // );
};

export default GradientBorder;