import React, { useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Home from './container/Home';
import DRC from './container/DRC';
import SkillWorks from './container/SkillWorks';
import Resume from './container/Resume';
import About from './container/About';
import VideoCodec from './container/VideoCodec';
import { HelmetProvider } from 'react-helmet-async';
import { pushCustomEventToClarity, pushCustomTagToClarity } from './components/Clarity';
import BlogPost from './container/BlogPost';
import Blog from './container/Blog';


function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    pushCustomEventToClarity("cv-redirect | " + to);
    navigate(to);
  });
  return null;
}

const App = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {

    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain && hostname.split('.').length > 2) {
      pushCustomTagToClarity("subdomain", subdomain);
    }

    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementsByClassName(id);
        if (element.length == 1) {
          element[0].scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/project/videocodec" element={<VideoCodec />} />
        <Route path="/p1" element={<Redirect to="/project/videocodec" />} />
        <Route path="/cv1" element={<Redirect to="/project/videocodec" />} />
        <Route path="/project/drc" element={<DRC />} />
        <Route path="/p2" element={<Redirect to="/project/drc" />} />
        <Route path="/cv2" element={<Redirect to="/project/drc" />} />
        <Route path="/project/skillworks" element={<SkillWorks />} />
        <Route path="/p3" element={<Redirect to="/project/skillworks" />} />
        <Route path="/cv3" element={<Redirect to="/project/skillworks" />} />
        <Route path="/about/resume" element={<Resume />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </HelmetProvider>
  )
}

export default App